import React, { useCallback, useState, useContext } from 'react';
import {
  Button,
} from 'antd';
// import useConfig from '@hooks/useConfig';
import {
  SettingOutlined,
} from '@ant-design/icons';
import ConfigForm from '@forms/config';
import Context from '@contexts/config';

export default function ConfigButton(props){
  const [formVisible, formVisibleSet] = useState(false);
  const { context, setContext } = useContext(Context);
  const handleClick = useCallback(() => {
    formVisibleSet(true);
  }, [formVisibleSet]);
  const handleCancel = useCallback(() => {
    formVisibleSet(false);
  }, [formVisibleSet]);
  const handleSubmitForm = useCallback((values) => {
    setContext(values);
    formVisibleSet(false);
  }, [setContext]);
  return (
    <>
      <Button 
        {...props}
        icon={<SettingOutlined />}
        onClick={handleClick}
        type="primary"
      >
        设置
      </Button>
      {
        formVisible && (
          <ConfigForm 
            value={context}
            visible={formVisible}
            onCancel={handleCancel}
            onSubmit={handleSubmitForm}
          />
        )
      }
    </>
  )
}