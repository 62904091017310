import React, { useState } from 'react';
import Context from '@contexts/config';
import useConfig from '@hooks/useConfig';
import { useEffect } from 'react';
import { useCallback } from 'react';

export default function ConfigProvider(props) {
  const { children } = props;
  const [config, setConfig] = useConfig({});
  const [context, setValue] = useState(() => config);
  const setContext = useCallback((nextValue) => {
    setValue(nextValue);
    setConfig(nextValue);
  }, [setConfig]);

  useEffect(() => {
    if(JSON.stringify(config) !== '{}') {
      setContext(config);
    }
  }, [setContext, config]);
  return (
    <Context.Provider 
      value={{
        context,
        setContext
      }}
    >
      {children}
    </Context.Provider>
  )
}