import React from 'react';
import { 
  Button, 
  Drawer, 
  Form, 
  Input, 
  Space,
  Switch
} from 'antd';

const ConfigForm = ({visible, onCancel, value, onSubmit}) => {
  const [form] = Form.useForm();
  const onFinish = (values) => {
    if(onSubmit){
      onSubmit(values);
    }
  };
  const onClickSubmit = () => {
    form.submit();
  };
  return (
    <Drawer
      title={'设置'}
      width={512}
      onClose={onCancel}
      open={visible}
      bodyStyle={{ paddingBottom: 80 }}
      destroyOnClose
      extra={
        <Space>
          <Button onClick={onCancel}>
            取消
          </Button>
          <Button onClick={onClickSubmit} type="primary">
            确定
          </Button>
        </Space>
      }
    >
      <Form 
        form={form}
        initialValues={value}
        onFinish={onFinish}
        hideRequiredMark
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 16 }}
        autoComplete="off"
      >
        <Form.Item
          name="title"
          label="标题"
          rules={[{ required: true, message: '标题' }]}
        >
          <Input
            style={{ width: '100%' }}
            placeholder="标题"
            defaultValue={'文献综述'}
          />
        </Form.Item>
        <Form.Item
          name="useTagCategory"
          label="启用标签分类"
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default ConfigForm;