import React, { useCallback } from 'react';
import moment from 'moment';
import {
  Button,
  message
} from 'antd';
import usePreference from '@hooks/usePreference';
import useReferenceList from '@hooks/useReferenceList';
import useConclusion from '@hooks/useConclusion';
import {
  ExportOutlined,
} from '@ant-design/icons';
import { exportJson } from '@utils';

export default function ExportButton(props){
  const [preference] = usePreference('');
  const [referenceList] = useReferenceList([]);
  const [conclusion] = useConclusion('');
  const handleExport = useCallback(() => {
    exportJson(`文献综述备份${moment().format('YYYY-MM-DD HH:mm:ss')}.json`, {
      preference,
      referenceList,
      conclusion
    });
    message.success('导出成功');
  }, [preference, referenceList, conclusion]);
  return (
    <Button 
      {...props}
      icon={<ExportOutlined />}
      onClick={handleExport}
      type="primary"
    >
      导出
    </Button>
  )
}