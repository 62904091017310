export function exportJson(fileName, json) {
  const jsonStr = (json instanceof Object) ? JSON.stringify(json) : json;
  const url = window.URL || window.webkitURL || window;
  const blob = new Blob([jsonStr]);
  const saveLink = document.createElementNS('http://www.w3.org/1999/xhtml', 'a');
  const hostname = window.location.hostname;
  saveLink.href = url.createObjectURL(blob);
  saveLink.download = [
    hostname !== 'localhost' ? hostname.replace(/\./gm, '_'): '',
    fileName
  ].filter(Boolean).join("--");
  saveLink.click();
};
