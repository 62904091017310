import React, { useCallback, useState } from 'react';
import {
  Button,
  Upload,
  message
} from 'antd';
import usePreference from '@hooks/usePreference';
import useReferenceList from '@hooks/useReferenceList';
import useConclusion from '@hooks/useConclusion';
import { UploadOutlined } from '@ant-design/icons';

export default function ImportButton(props){
  const { onSuccess, ...restProps} = props;
  const [fileList, setFileList] = useState([]);
  const [,setPreference] = usePreference('');
  const [,setReferenceList] = useReferenceList([]);
  const [,setConclusion] = useConclusion('');
  const handleFileChange = useCallback(({file}) => {
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = function(){
      const result = JSON.parse(this.result);
      const {
        preference, referenceList, conclusion
      } = result;
      setPreference(preference);
      setReferenceList(referenceList);
      setConclusion(conclusion);
      setFileList([]);
      message.success('导入成功');
      if(onSuccess) {
        onSuccess(file);
      }
    }
  }, [setPreference, setReferenceList, setConclusion, onSuccess]);
  return (
    <Upload
      onChange={handleFileChange}
      accept={'application/json'}
      beforeUpload={(file) => {
        setFileList([...fileList, file])
        return false;
      }}
      showUploadList={false}
      fileList={fileList}
    >
      <Button 
        {...restProps}
        icon={<UploadOutlined />}
        type="primary"
      >
        导入
      </Button>
    </Upload>
  )
}