import React, { 
  useState, 
  useEffect, 
  useLayoutEffect, 
  createRef, 
  useCallback,
  useContext
} from 'react';
import {
  Row,
  Col,
  Popover,
  Space,
  Button,
} from 'antd';
import {
  CloseOutlined,
  EditOutlined,
  NumberOutlined,
  AimOutlined
} from '@ant-design/icons';
import ReferenceForm from '@forms/reference';
import Context from '@contexts/config';
import usePreference from '@hooks/usePreference';
import useReferenceList from '@hooks/useReferenceList';
import useConclusion from '@hooks/useConclusion';
import './index.less';
import {
  groupBy
} from '@utils';

function tagGroupByIterator(record) {
    if(Array.isArray(record.tags)){
      return record.tags.join();
    } else {
      return record.tags;
    }
}

const Preference = () => {
  const [text] = usePreference('');
  const pList = text?.split("\n")?.filter(Boolean);
  if(pList?.length) {
    return (
      <>
        <h2>1. 前言</h2>
        {
          pList.map((pText, index) => (
            <p
              key={index}
            >
              {pText}
            </p>
          ))
        }
      </>
    );
  } else {
    return '';
  }
};

const Conclusion = () => {
  const [text] = useConclusion('');
  const pList = text?.split("\n")?.filter(Boolean);
  if(pList?.length) {
    return (
      <>
        <h2>3. 文献综述总结</h2>
        {
          pList.map((pText, index) => (
            <p
              key={index}
            >
              {pText}
            </p>
          ))
        }
      </>
    );
  } else {
    return '';
  }
};

const PreviewPage = () => {
  const $container = createRef();
  const { context } = useContext(Context);
  const [editRecord, setEditRecord] = useState();
  const [dataSource, saveDataSource] = useReferenceList([]);
  const [wordCount, setWordCount] = useState(0);
  const [myDataSource, setMyDataSource] = useState([]);
  useEffect(() => {
    if(Array.isArray(dataSource)) {
      setMyDataSource(dataSource.filter((record) => record.active));
    }
  }, [dataSource]);
  useLayoutEffect(() => {
    setWordCount($container?.current?.textContent?.length);
  }, [dataSource, $container]);
  const regions = groupBy(myDataSource, 'region');
  let references = [];
  const handleSubmitForm = useCallback(({isNew, ...record}) => {
    const index = dataSource.findIndex((item) => item.id === record.id);
    if(index !== -1) {
      dataSource.splice(index, 1, {...record});
    } else {
      dataSource.push({...record});
    }
    saveDataSource(dataSource.slice());
    setEditRecord();
  }, [dataSource, saveDataSource]);
  const handleHideReference = useCallback((targetReference) => {
    const nextDataSource = dataSource.map((record) => {
      if(targetReference === record.reference){
        record.active = false;
      }
      return record;
    });
    saveDataSource(nextDataSource);
  }, [dataSource, saveDataSource]);
  const handleEditReference = useCallback((targetReference) => {
    const targetRecord = dataSource.find((record) => {
      return targetReference === record.reference;
    });
    setEditRecord(targetRecord);
  }, [dataSource, setEditRecord]);
  const handleJumpToReference = useCallback((referenceNumber) => {
    const $target = document.getElementById(`reference-list-item__${referenceNumber}`);
    if($target){
      $target.scrollIntoView(true);
    }
  }, []);
  const handleJumpToReferenceUsed = useCallback((referenceNumber) => {
    const $target = document.getElementById(`reference-used-item__${referenceNumber}`);
    if($target){
      $target.scrollIntoView(true);
    }
  }, []);

  const tagListAll = dataSource.reduce((ret, record) => {
    if(Array.isArray(record.tags)){
      ret.push(...record.tags);
    } else if(record.tags){
      ret.push(record.tags);
    }
    return ret;
  }, []);
  const tagList = Array.from(new Set(tagListAll));
  return (
    <article className="page-preview">
      <Row>
        <Col flex={1} />
        <Col flex={'none'}>
          <span>字数: {wordCount || 0}</span>
        </Col>
      </Row>
      <div ref={$container}>
        <h1>{ context?.title || '文献综述'}</h1>
        <Preference />
        <h2>2. 相关文献研究现状</h2>
        {
          Object.keys(regions).map((region, index) => {
            let referenceList = [];
            if(context?.useTagCategory) {
              const tagsGroup = groupBy(regions[region], tagGroupByIterator);
              referenceList = Object.keys(tagsGroup).map((tag) => {
                const list = tagsGroup[tag];
                list.sort((articleA, articleB) => {
                  if(articleA.year > articleB.year) {
                    return 1;
                  } else {
                    return -1;
                  }
                });
                return [tag, list];
              });
            } else {
              referenceList = [
                ['', regions[region]]
              ];
            }
            return (
              <React.Fragment key={region}>
                <h3>{`2.${index + 1} ${region}研究现状`}</h3>
                {
                  referenceList.map(([tag, list], ii) => {
                    return (
                      <React.Fragment key={tag}>
                        {
                          tag && (
                            <p>({ii+1})关于{tag}方面的研究</p>
                          )
                        }
                        <p>
                          {
                            list.map(({id, content, reference}) => {
                              references.push(reference);
                              const referenceIndex = references.length;
                              const referenceText = `[${referenceIndex}]`;
                              const popoverContent = (
                                <Space>
                                  <Button 
                                    icon={<CloseOutlined />}
                                    type="primary"
                                    onClick={() => {
                                      handleHideReference(reference);
                                    }}
                                  >
                                    隐藏文献
                                  </Button>
                                  <Button 
                                    icon={<EditOutlined />}
                                    type="primary"
                                    onClick={() => {
                                      handleEditReference(reference);
                                    }}
                                  >
                                    编辑文献
                                  </Button>
                                  <Button 
                                    icon={<NumberOutlined />}
                                    type="primary"
                                    onClick={() => handleJumpToReference(referenceIndex)}
                                  >
                                    {`跳转到文献${referenceText}`}
                                  </Button>
                                </Space>
                              )
                              return (
                                <React.Fragment key={id}>
                                  {content}
                                  <Popover content={popoverContent} trigger="hover">
                                    <sup 
                                      className="page-preview__reference-sup"
                                      id={`reference-used-item__${referenceIndex}`}
                                    >
                                      {`[${referenceIndex}]`}
                                    </sup>
                                  </Popover>
                                </React.Fragment>
                              );
                            })
                          }
                        </p>
                      </React.Fragment>
                    )
                  })
                }
              </React.Fragment>
            );
          })
        }
        <Conclusion />
      </div>

      <h2>引用文献</h2>
      <ol className="page-preview__reference-list">
        {
          references.map((reference, index) => {
            const referenceNumber = index + 1;
            return (
              <li className="page-preview__reference-item" id={`reference-list-item__${referenceNumber}`} key={reference}>
                {reference}
                <span>
                  <CloseOutlined 
                    className="page-preview__reference-btn"
                    onClick={() => {
                      handleHideReference(reference);
                    }}
                  />
                  <EditOutlined
                    className="page-preview__reference-btn"
                    onClick={() => {
                      handleEditReference(reference);
                    }}
                  />
                  <AimOutlined 
                    className="page-preview__reference-btn"
                    onClick={() => {
                      handleJumpToReferenceUsed(referenceNumber);
                    }}
                  />
                </span>
              </li>
            )
          })
        }
      </ol>
      {
        editRecord && (
          <ReferenceForm 
            value={editRecord}
            visible={editRecord}
            tagList={tagList}
            index={dataSource.length}
            onCancel={() => setEditRecord()}
            onSubmit={handleSubmitForm}
          />
        )
      }
    </article>
  )
};

export default PreviewPage;